<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="service-setting-wrapper">
          <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
            <h4 class="">Service/List</h4>
          </div>
          <div class="accordion collapse-icon accordion-icon-rotate" id="accordionService">

            <div class="card">
              <div class="card-header" id="settingServiceCodePrefixHeading">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#settingServiceCodePrefixCollapse" aria-expanded="false" aria-controls="collapseServiceCodePrefix">
                  <span class="text-capitalize service-title">Service Code Prefix</span>
                </button>
              </div>
              <div id="settingServiceCodePrefixCollapse" class="collapse" aria-labelledby="settingServiceCodePrefixHeading" data-parent="#accordionService">
                <div class="card-body">
                  <SettingServiceCodePrefix :service-code-prefix="this.serviceCodePrefix"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="settingServiceCodeSuffixHeading">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#settingServiceCodeSuffixCollapse" aria-expanded="false" aria-controls="collapseServiceCodeSuffix">
                  <span class="text-capitalize service-title">Service Code Suffix</span>
                </button>
              </div>
              <div id="settingServiceCodeSuffixCollapse" class="collapse" aria-labelledby="settingServiceCodeSuffixHeading" data-parent="#accordionService">
                <div class="card-body">
                  <SettingServiceCodeSuffix :service-code-suffix="this.serviceCodeSuffix"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="settingServiceStatePriceRateHeading">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#settingServiceStatePriceRateCollapse" aria-expanded="false" aria-controls="collapseServiceStatePriceRate">
                  <span class="text-capitalize service-title">Service State Price Rate</span>
                </button>
              </div>
              <div id="settingServiceStatePriceRateCollapse" class="collapse" aria-labelledby="settingServiceStatePriceRateHeading" data-parent="#accordionService">
                <div class="card-body">
                  <SettingServiceStatePriceRate :service-state-price-rate="this.serviceStatePriceRate"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="settingServiceOfferPriceHeading">
                <button class="btn btn-block text-left w-100" role="button" data-toggle="collapse"
                        data-target="#settingServiceOfferPriceCollapse" aria-expanded="false" aria-controls="settingServiceOfferPriceCollapse">
                  <span class="text-capitalize service-title">Service Offer Price Rate</span>
                </button>
              </div>
              <div id="settingServiceOfferPriceCollapse" class="collapse" aria-expanded="false" aria-labelledby="settingServiceOfferPriceHeading" data-parent="#accordionService">
                <div class="card-body">
                  <SettingServiceOfferPrice :service-offer-price="this.serviceOfferPrice"/>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/backEnd/AppLayout";
import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import SettingServiceCodePrefix from "@/views/backEnd/settings/service/includes/SettingServiceCodePrefix";
import SettingServiceStatePriceRate from "@/views/backEnd/settings/service/includes/SettingServiceStatePriceRate";
import SettingServiceCodeSuffix from "@/views/backEnd/settings/service/includes/SettingServiceCodeSuffix";
import SettingServiceOfferPrice from "@/views/backEnd/settings/service/includes/SettingServiceOfferPrice";
export default {
  name: "SettingServiceList.vue",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
    SettingServiceCodePrefix,
    SettingServiceCodeSuffix,
    SettingServiceStatePriceRate,
    SettingServiceOfferPrice
  },
  data() {
    return {
      getSettingsParams: {
        type: ["service"],
        key: [
          'service_code_prefix',
          'service_code_suffix',
          'service_state_price_rate',
          'service_offer_price',
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      serviceCodePrefix: 'appSettings/settingServiceCodePrefix',
      serviceCodeSuffix: 'appSettings/settingServiceCodeSuffix',
      serviceStatePriceRate: 'appSettings/settingServiceStatePriceRate',
      serviceOfferPrice: 'appSettings/settingServiceOfferPrice',
    }),
  },

  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.service-wrapper .accordion .card {
  overflow: visible !important;
}

.service-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>